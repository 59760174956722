<template>
  <div>
    <template>
        <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
                <b-breadcrumb :items="breadCrumb()" />
            </div>
        </div>
    </template>

    <b-card>

        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
            <b-col md=12>
                <div class="demo-inline-spacing float-right ">                
                    <b-button variant="danger" class="mt-0 mb-1" @click="$router.push({name:'toilet-item', params: { site_id: site ? site : null } })" v-if="disabledOptions == false">
                        <feather-icon icon="TargetIcon" class="mr-25"/>
                        <span>Items</span>
                    </b-button>
                </div>
            </b-col>
        </b-row>

        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body">
                {{error_message}}
            </div>
        </b-alert>

        <b-row>

          <b-col md="12">              
              <h4 class="card-title float-left">Toilet Inspection Checklist</h4>              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="3">
              <b-form-group label="Project Site" class="required">
                  <b-form-select v-model="site" @change="siteChange" :disabled="disabledOptions">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
          </b-col>

          <b-col md="3">
              <b-form-group label="Shift" class="required">
                  <b-form-select v-model="shift" @change="getLocationData()" :disabled="disabledOptions">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
          </b-col>

          <b-col md="3" >
            <b-form-group label="Date" class="required"> 
              <b-form-datepicker
                v-model="date"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en"
                @input="getLocationData()"
                :disabled="disabledOptions"
              />
            </b-form-group>
          </b-col>

          <b-col md="3" >
            <b-form-group label="Checked By" class="required">
              <b-form-input placeholder="3 to 50 characters" v-model="checked_by" autocomplete="off" :disabled="disabledOptions"/>
            </b-form-group>
          </b-col>

        </b-row>
    
    </b-card>
    
    <b-form @submit="formSubmit">
        
        <b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">
            <div v-if="site && shift != ''">
                <b-table
                    responsive
                    show-empty
                    ref = "refUserListTable"
                    class = "position-relative "
                    primary-key = "id"
                    empty-text = "No records found"
                    :fields = "tableColumns"
                    :items = "list.building && list.level && list.toilet_type && (list.toilet_no == 'No. 1' || list.toilet_no == 'No. 2' || (list.toilet_no == 'Other' && list.toilet_other_text.length > 0)) ? list.data : []"
                    v-if="site != ''"
                >

                    <template slot="thead-top" slot-scope="data">
                        <tr>                
                            <th colspan="5"> 
                              <b-row>
                                <b-col md="2">
                                    <b-form-select v-model="list.building" @change="buildingChange(list.building,i); getLevels(list.building, i);" :disabled="(checkdisabled() && disabledOptions)">
                                        <b-form-select-option value="" disabled>Select Building</b-form-select-option>
                                        <b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id">{{building.name | capitalize}}</b-form-select-option>
                                    </b-form-select>
                                </b-col>

                                <b-col md="2">
                                    <b-form-select v-model="list.level" :disabled="(checkdisabled() && disabledOptions) ? true : (list.building ? false : true)" @change="list.toilet_type = ''; list.toilet_no = ''; list.toilet_other_text = ''; getBuildingsWithLocation(list.building, list.level, i);">
                                        <b-form-select-option value="" disabled>Select Level</b-form-select-option>
                                        <b-form-select-option :value="lvl" v-for="(lvl, ind) in list.levels" :key="ind">{{lvl | capitalize}}</b-form-select-option>
                                    </b-form-select>
                                </b-col>

                                <b-col md="2">
                                    <b-form-select v-model="list.toilet_type" :disabled="(checkdisabled() && disabledOptions) ? true : (list.building && list.level ? false : true)" @change="list.toilet_no = ''; list.toilet_other_text = ''">
                                        <b-form-select-option value="" disabled>Toilet Type</b-form-select-option>
                                        <b-form-select-option value="Male">Male</b-form-select-option>
                                        <b-form-select-option value="Female">Female</b-form-select-option>
                                        <b-form-select-option value="Handicap">Handicap</b-form-select-option>
                                    </b-form-select>
                                </b-col>
                                
                                <b-col md="2">
                                    <b-form-select v-model="list.toilet_no" :disabled="(checkdisabled() && disabledOptions) ? true : (list.building && list.level && list.toilet_type ? false : true)" @change="list.toilet_other_text = ''; checkUnique(i)">
                                        <b-form-select-option value="" disabled>Toilet No.</b-form-select-option>
                                        <b-form-select-option value="No. 1">No. 1</b-form-select-option>
                                        <b-form-select-option value="No. 2">No. 2</b-form-select-option>
                                        <b-form-select-option value="Other">Other</b-form-select-option>
                                    </b-form-select>
                                </b-col>

                                <b-col md="2">
                                    <b-form-select v-model="list.toilet_other_text" v-if="list.toilet_no == 'Other'" @change="checkUnique(i)" :disabled="(checkdisabled() && disabledOptions)">
                                        <b-form-select-option value="" disabled>Select Other</b-form-select-option>
                                        <b-form-select-option :value="oth" v-for="(oth, indx) in list.all_location" :key="indx">{{oth | capitalize}}</b-form-select-option>
                                    </b-form-select>
                                </b-col>

                                <b-col md="2">
                                    <b-button
                                        size="sm"
                                        variant="danger"
                                        class="mr-1 float-right"
                                        @click="removeData(i)"
                                        v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == false && checklistData[0].building && checklistData[0].level && checklistData[0].toilet_type && (checklistData[0].toilet_no == 'No. 1' || checklistData[0].toilet_no == 'No. 2' || (checklistData[0].toilet_no == 'Other' && checklistData[0].toilet_other_text.length > 0))" :disabled="(checkdisabled() && disabledOptions)">
                                        Remove
                                    </b-button>

                                    <b-button
                                        size="sm"
                                        variant="danger"
                                        class="mr-1 float-right"
                                        @click="removeDBBuilding(i,checklistData[i])"
                                        v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == true && checklistData[0].building && checklistData[0].level && checklistData[0].toilet_type && (checklistData[0].toilet_no == 'No. 1' || checklistData[0].toilet_no == 'No. 2' || (checklistData[0].toilet_no == 'Other' && checklistData[0].toilet_other_text.length > 0))" :disabled="(checkdisabled() && disabledOptions)">
                                        Remove
                                    </b-button>
                                </b-col>


                                <!-- <b-col md="3" offset-md="9" v-if="list.toilet_no == 'Other'">
                                    <b-form-input v-model="list.toilet_other_text" class="mb-2 mr-sm-2 mb-sm-0 mt-1" autocomplete="off" @change="checkUnique(i)"></b-form-input>
                                </b-col> -->

                                <!-- <b-col md="6">
                                  <b-row>

                                    <b-col md="4">
                                      <b-form-checkbox v-model="list.toilet_no_1" 
                                        :disabled="list.building && list.level && list.toilet_type ? false : true" 
                                        class="mb-2 mr-sm-2 mb-sm-0" 
                                        value="t1" unchecked-value="no" 
                                        style="margin-bottom: 0; font-weight: normal; margin-top: 2px; text-transform: capitalize; font-size:10px"
                                        @input="updateToiletNo('toilet_no_1', i); checkUnique(i)"
                                      >
                                      Toilet No 1
                                      </b-form-checkbox>
                                    </b-col>

                                    <b-col md="4">
                                      <b-form-checkbox v-model="list.toilet_no_2" 
                                        :disabled="list.building && list.level && list.toilet_type ? false : true" 
                                        class="mb-2 mr-sm-2 mb-sm-0" 
                                        value="t2" unchecked-value="no" 
                                        style="margin-bottom: 0; font-weight: normal; margin-top: 2px; text-transform: capitalize; font-size:10px"
                                        @input="updateToiletNo('toilet_no_2', i); checkUnique(i)"
                                      >
                                        Toilet No 2
                                      </b-form-checkbox>
                                    </b-col>

                                    <b-col md="4">
                                      <b-form-checkbox v-model="list.toilet_other" 
                                        :disabled="list.building && list.level && list.toilet_type ? false : true" 
                                        class="mb-2 mr-sm-2 mb-sm-0" 
                                        value="t3" unchecked-value="no" 
                                        style="margin-bottom: 0; font-weight: normal; margin-top: 2px; text-transform: capitalize; font-size:10px"
                                        @input="updateToiletNo('toilet_other', i);"
                                      >
                                        Other
                                      </b-form-checkbox>
                                    </b-col>

                                    <b-col md="4" offset-md="8" v-if="list.toilet_other == 't3'">
                                      <b-form-input v-model="list.toilet_other_text" class="mb-2 mr-sm-2 mb-sm-0 mt-1" autocomplete="off" @input="checkUnique(i)"></b-form-input>
                                    </b-col>

                                  </b-row>
                                </b-col> -->
                              </b-row>

                            </th>
                            <!-- <th colspan="1">
                                <b-button
                                    size="sm"
                                    variant="danger"
                                    class="mr-1 float-right"
                                    @click="removeData(i)"
                                    v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == false && checklistData[0].building && checklistData[0].level && checklistData[0].toilet_type && (checklistData[0].toilet_no == 'No. 1' || checklistData[0].toilet_no == 'No. 2' || (checklistData[0].toilet_no == 'Other' && checklistData[0].toilet_other_text.length > 0))">
                                    Remove
                                </b-button>

                                <b-button
                                    size="sm"
                                    variant="danger"
                                    class="mr-1 float-right"
                                    @click="removeDBBuilding(i,checklistData[i])"
                                    v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == true && checklistData[0].building && checklistData[0].level && checklistData[0].toilet_type && (checklistData[0].toilet_no == 'No. 1' || checklistData[0].toilet_no == 'No. 2' || (checklistData[0].toilet_no == 'Other' && checklistData[0].toilet_other_text.length > 0))">
                                    Remove
                                </b-button>
                            </th> -->
                        </tr>
                    </template>

                    <template #cell(s_no)="items">
                        <p>{{items.index + 1}}</p>         
                    </template>

                    <template #cell(name)="items">
                        <p>{{items.name}}</p>         
                    </template>

                    <template #cell(condition)="items">
                        <b-form-select v-model="items.item.condition" @change="updateRemarks(i, items.index)">
                        <b-form-select-option value="" disabled>Select Condition</b-form-select-option>
                        <b-form-select-option value="Excellent - 5">Excellent - 5</b-form-select-option>
                        <b-form-select-option value="Good - 4">Good - 4</b-form-select-option>
                        <b-form-select-option value="Average - 3">Average - 3</b-form-select-option>
                        <b-form-select-option value="Below Average - 2">Below Average - 2</b-form-select-option>
                        <b-form-select-option value="Fail - 1">Fail - 1</b-form-select-option>
                        <b-form-select-option value="N/A">N/A</b-form-select-option>
                        </b-form-select>
                    </template>

                    <template #cell(action_plan)="items">
                        <b-form-input
                            placeholder=""
                            v-model="items.item.action_plan"
                            type="text"
                            autocomplete="off"
                        />               
                    </template>

                    <!-- <template #cell(acknowledge)="items">
                        <b-form-input placeholder="" v-model="items.item.acknowledge" type="text" autocomplete="off" />
                    </template> -->

                    <template #cell(remarks)="items">
                        <b-form-input placeholder="" v-model="items.item.remarks" autocomplete="off" />
                    </template>
                
                </b-table>
            </div>
        </b-card>

        <b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[0].building && checklistData[0].level && checklistData[0].toilet_type && (checklistData[0].toilet_no == 'No. 1' || checklistData[0].toilet_no == 'No. 2' || (checklistData[0].toilet_no == 'Other' && checklistData[0].toilet_other_text.length > 0))">
            <b-row>
                <b-col md="3"></b-col>

                <b-col md="3">

                    <b-form-group label="Submitted Date :" class="required">
                        <b-form-datepicker v-model="submitted_date" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" />
                    </b-form-group>

                </b-col>

                <b-col md="3">

                    <b-form-group label="Submitted Time :" class="required">
                        <flat-pickr v-model="submitted_time" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}" />
                    </b-form-group>

                </b-col>
            
                <b-col md="3">

                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="mr-1 float-right"
                        @click="addMore"
                        v-if="site != ''"
                        :disabled="(checkdisabled() && disabledOptions)"
                        >
                        Add More
                    </b-button>

                </b-col>
                
            </b-row>

            <b-row>

                <b-col cols="12" class="mb-2 mt-2">

                <div class="pb-2">
                    <b-form-group label="Signature" class="required"  style="font-size:20px;color:white;text-align:center" />
                    <center>
                        <div @click="openSignature('supervisor')" v-if="crew_signature.image == null">
                        <!--<template #aside>-->
                            <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                        <!--</template>-->
                        </div>

                        <div class="d-flex flex-wrap">
                            <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                        </div>

                        <img :src="crew_signature.image" width="200px" class="rounded" height="180" v-if="crew_signature.image != null" style="background: #ffffff">
                    </center>

                    <center>
                        <b-button variant="danger" class="mt-1 ml-2" v-if="crew_signature.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions">
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </center>
                </div>

                </b-col>
                
            </b-row>

            <b-row class="mt-1">
                <b-col>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class = "mr-1" variant="outline-secondary" @click="goBack()">
                        Cancel
                    </b-button>

                    <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(id)" v-if="id != null && typeof $route.params.id == 'undefined'" >
                        Discard Draft
                    </b-button>  

                    <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "primary" class = "mr-1" type="submit" @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined'">
                        {{ id == null ? 'Save as Draft' : 'Update Draft'}}
                    </b-button>

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1" type="submit" @click="status = 'completed'">
                        {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
                    </b-button>

                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        v-if="typeof $route.params.id != 'undefined' && ledit == false"
                        @click="openSignature('approver')"
                      >
                        {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
                    </b-button>

                </b-col>
            </b-row>

        </b-card>

    </b-form>

    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import Bus from "../../../event-bus";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,BBreadcrumb,DatePicker
  },
  
  data() {
    return {
        draftData:null,
        date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),        
        //site:this.$route.params.site_id,
        id: null,
        site:'',
        checked_by:this.$store.getters.currentUser.full_name,
        shift:'',
        building:'',
        buildings:[],
        location_names:[],
        sites:[],
        shifts:[],
        status: 'draft',

        signature:{image:null,name:'',default:'no'},

        showDismissibleAlert:false,
        error_message:'',
        doc_icon: require('@/assets/images/doc.png'),
        checklistData:[],
        building_ids:[],
      
        openedSignModel:null,
        submitted_date : '',
        submitted_time: '',
        signature_time: '',
        crew_signature : {
            image:null,
            name:'',
            type: 'supervisor',
            default:'no'
        },
            tempSign : {
            image:null,
            name:'',
        },
        show_default:false,
        having_default:false,
        disabledOptions:false,
        supervisorSign : {
            image:null,
            name:'',
            type: 'supervisor',
            default:'no'
        },
        approve_signature_time:null,
        approve_remark:'',
        ledit : false,

        tableColumns:[
            { key: 's_no', label: 'SNo', sortable: false , thStyle:  {width: '5%'}},
            { key: 'item', label: 'Item', sortable: false , thStyle:  {width: '26%'}},
            { key: 'condition', label: 'Condition', sortable: false , thStyle:  {width: '23%'}},
            { key: 'action_plan', label: 'Action Plan', sortable: false , thStyle:  {width: '23%'}},
            // { key: 'acknowledge', label: 'Acknowledge', sortable: false , thStyle:  {width: '19%'}},
            { key: 'remarks', label: 'Remarks', sortable: false , thStyle:  {width: '23%'}}
        ],      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {      
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    getLevels(building, index){
        this.checklistData[index].levels = [];
        this.checklistData[index].level  = '';
        this.checklistData[index].toilet_type = '';
        this.checklistData[index].toilet_no = '';
        this.checklistData[index].all_location = '';
        this.checklistData[index].toilet_other_text = '';

        this.buildings.forEach(el => {
            if(el._id == building){
                this.checklistData[index].building_name = el.name;
        
                if (el.basement == 'yes') {
                    for (var i = 1; i <= el.no_of_basements; i++) {
                        this.checklistData[index].levels.push('Basement '+i);
                    }
                }
        
                for (var j = 1; j <= el.no_of_levels; j++) {
                    this.checklistData[index].levels.push('Level '+j);
                }
            }            
        });
    },
    
    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
                role:this.$store.getters.currentUser.role,
                //om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }
                return this.sites;
            }
        });
    },

    formSubmit(e){
        e.preventDefault();

        if(this.status == 'draft'){
            this.actualForm();            
        } else {    
            this.decisionAlert('Are you sure want to submit this report ?')
            .then(result => {
                if (result.value) {
                    this.actualForm();            
                }
            })
        }
    },

    actualForm(){    
        return this.$store.dispatch(POST_API, {
            data:{
                id : this.id,
                date : this.date,
                site :this.site,
                checked_by : this.checked_by,
                shift : this.shift,
                checklistData : this.checklistData,
                status: this.status,
                crew_signature: this.crew_signature,
                submitted_date: this.submitted_date,
                submitted_time: this.submitted_time,
                form_id:this.$route.params.id,
                approve_signature: this.supervisorSign,
                approve_signature_time: this.approve_signature_time,
                approve_remark: this.approve_remark,
                role:this.$store.getters.currentUser.role,
            },
            api : '/api/submit-toilet-inspection-checklist'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message        = this.$store.getters.getErrors;
                this.errorAlertCF()
            } else {
                this.showDismissibleAlert = false;

                var data = this.$store.getters.getResults.data;

                this.successAlert().then((result) => {

                    if (this.sites.length == 1) {                                
                        this.site = this.sites[0]._id;                
                    }else{
                        this.site = '';
                    }
                    this.siteChange();

                    if (data != null) {
                        window.open(data, '_blank');
                    }
                    localStorage.setItem('routeCFCheck','no');
                    if (this.$route.params.id) {
                        this.$router.push({name:'toilet-inspection-checklist-report'});
                    }else{

                        this.$router.push({ name:'custom-forms' });
                    }
                });
            }
        });
    },

    getLocationData(){
      localStorage.setItem('routeCFCheck','no');

      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
             shift:this.shift != '' ? this.shift : null,
             date:this.date,
           },
           api: '/api/toilet-inspection-locations-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.id = data.id;
                this.checklistData = data.checkListData;
                this.crew_signature = data.crew_signature;
                this.submitted_date = data.submitted_date;
                this.submitted_time = data.submitted_time;
                return this.checklistData;
            }
        });
    },

    getShiftData(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
           },
           api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.shifts = data;
                return this.shifts;
            }
        });
    },
   
    siteChange(){
        this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
        this.shift = '';

        if (this.site != '') {
            this.getShiftData();
            this.getBuildings();
            this.getLocationData();        
        }
    },

    buildingChange(id,i){
        this.checklistData[0].building != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');

        if (id != '') {

            return this.$store.dispatch(POST_API, {
                data:{
                    building:id,
                    site:this.site
                },
                api: '/api/toilet-inspection-building-location-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;

                    this.checklistData[i].data = data;
                    return this.checklistData;
                }
            });


        }else{
            this.checklistData[i].data = [];
        }
    },
    
    addMore(){      
        var order = 0;

        if (this.checklistData.length > 0) {
            var lastIndex = this.checklistData.length - 1;
            order = this.checklistData[lastIndex].order + 1;
        }

        var obj = {
            building    : '',
            levels      : [],
            level       : '',
            toilet_type : '',
            toilet_no   : '',
            all_location : [],
            toilet_other_text : '',
            data        : [],
            order       : order,
            db_building : false,
        };

        this.checklistData.push(obj);
    },

    removeData(index){
        this.checklistData.splice(index,1);
        if (this.checklistData.length == 0) {
            this.addMore();
        }
    },

    removeDBBuilding(index,item){
        var msg = 'Are you sure want to remove this building record?';

        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        order : item.order,
                        building_id : item.building,
                        date : this.date,
                        site :this.site,
                        shift : this.shift,
                    },
                    api : "/api/remove-tic-db-building",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.showAlert();
                    } else {
                        this.successAlert()
                        .then(() => {

                            this.checklistData.splice(index,1);
                            if (this.checklistData.length == 0) {
                            this.addMore();
                            }
                            
                        });
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    },
    
    getBuildings(){
        return this.$store.dispatch(POST_API, {
            data:{
                form: 'Toilet Inspection Checklist',
                role:this.$store.getters.currentUser.role,
                site:this.site
            },
            api: '/api/all-buildings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.buildings = this.$store.getters.getResults.data;
                return this.buildings;
            }
        });
    },
    
    getBuildingsWithLocation(building, level, index){
        return this.$store.dispatch(POST_API, {
            data:{
                role:this.$store.getters.currentUser.role,
                site:this.site,
                form: 'Toilet Inspection Checklist',
                building: building,
                level: level
            },
            api: '/api/all-buildings-with-location'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                // this.location_names = this.$store.getters.getResults.data;
                // return this.location_names;
                this.checklistData[index].all_location = this.$store.getters.getResults.data;
            }
        });
    },

    discardItem(id){
        var msg = 'Are you sure want to discard this record?';

        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : id,
                        status : 'deleted'
                    },
                    api : "/api/change-status-toilet-inspection-report-data",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.showAlert();
                    } else {
                        localStorage.setItem('routeCFCheck','no');
                        this.successAlert()
                        .then(() => {
                            this.flag = true;
                            this.refreshData();
                            if (this.$route.params.id) {
                                this.$router.push({name:'toilet-inspection-checklist-report'});
                            }else{

                                this.$router.push({ name:'custom-forms' })
                            }
                        });
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    },

    refreshData(){
        this.flag = false;
        this.id = null;
        this.site = '';

        this.checked_by = this.$store.getters.currentUser.full_name;
        this.date = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
        this.shift = '';
        this.status = 'completed';
        this.checklistData = [];

        this.crew_signature = {
            image:null,
            name:'',
            type: 'supervisor',
            default:'no'
        };
        
        this.submitted_date = '';
        this.submitted_time = '';
        
        this.openedSignModel=null;
    },
     
    breadCrumb(){

        if(this.$route.params.id){

            var item = [{
              to:{name:'client-dashboard'},
              text: 'Dashboard',
            },{
              to:null,
              text: 'Reports',
            },{
              to:{name:'custom-forms-dashboard'},
              text: 'Form Dashboard',
            },{
              to:{name:'custom-forms-reports'},
              text:'Custom Forms'
            },{
              to:{name:'toilet-inspection-checklist-report'},
              text:'Toilet Inspection Checklist',
              
            },{
              to:null,
              text:'Edit',
              active:true
            }];
            return this.filterReportBreadCrum(item);

        }else{

            var item = [{
              to:{name:'client-dashboard'},
              text: 'Dashboard',
            },{
              to:{name:'custom-forms'},
              text: 'Custom Forms',
            },{
              to:null,
              text:'Toilet Inspection Checklist',   
              active:true             
            }];
          return item;

        }
    },

    supervisorImageUpload(event){
        var input = event.target;
        var files = event.target.files

        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var image = input.files[0];

            if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Please upload .jpg, .jpeg, .jpg images only',
                    showConfirmButton: false,
                    timer: 1500
                });

                this.$refs.supervisorSInput.value=null;

            } else if (image.size > 2097152) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Maximum 2 MB files allowed to be upload.',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.$refs.supervisorSInput.value=null;
            } else {
                reader.onload = (e) => {
                    this.crew_signature.image = e.target.result;
                    this.crew_signature.name = image.name;
                    this.crew_signature.default = 'no';
                }
            }
        }
    },
    removeSign(sign){
        this.crew_signature.image = null;
        this.crew_signature.name = '';
        this.crew_signature.default = 'no';
    },

    useDefault(){

        return this.$store.dispatch(POST_API, {
            data:{
                id:this.$store.getters.currentUser._id
            },
            api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  this.having_default = false;
                }else{
                  this.tempSign = {
                    image : data.default_signature,
                    name : 'signature.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      this.$refs['supervisorSInput'].click();
      this.$refs['signatureModel'].hide();

    },
    openSignature(type){
      
      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');

      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{
        if (this.openedSignModel == 'supervisor') {

            this.crew_signature.image = data;
            this.crew_signature.name = 'signature.png';
            this.crew_signature.type = 'supervisor';
            this.crew_signature.default = 'no';
            this.$refs['signatureModel'].hide();
        }else{
            this.supervisorSign.image = data;
            this.supervisorSign.name = 'signature.png';
            this.supervisorSign.type = 'supervisor';
            this.supervisorSign.default = 'no';
            this.status = 'approved';
            this.actualForm();
        }
      }
    },
    saveDefault(){

        if (this.openedSignModel == 'supervisor') {
          this.crew_signature = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes'
          }
        }else{

          this.supervisorSign = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes',
            type:'supervisor'
          }
          this.status = 'approved';
          this.actualForm();

        }
        
      this.$refs['signatureModel'].hide();
    },

    goBack(){

      var msg = 'Do you want to Go back without saving your report?';
      

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
            localStorage.setItem('routeCFCheck','no');
            if (this.$route.params.id) {
                this.$router.push({name:'toilet-inspection-checklist-report'});
            }else{

                this.$router.push({ name:'custom-forms' })
            }
          }
      })
    },

    checkUnique(i){
      var arr = [];

      this.checklistData.forEach((item) => {
          if (arr.indexOf(item.building+item.level+item.toilet_type+item.toilet_no+item.toilet_other_text) >= 0) {
              this.customAlert('Building Information already Exists.', '', 'warning');
              if(item.toilet_no == 'Other'){
                this.checklistData[i].toilet_other_text = '';
              } else {
                this.checklistData[i].toilet_no = '';
                this.checklistData[i].toilet_other_text = '';
              }
          }else{
              arr.push(item.building+item.level+item.toilet_type+item.toilet_no+item.toilet_other_text);
          }
      })
    },
    getFormDetails(){
      /*localStorage.setItem('routeCFCheck','yes');*/

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/get-toilet-inspection-report-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.id  = data._id;
                this.date  = data.checked_date;
                this.site  = data.site;
                this.checked_by  = data.checked_by;
                this.shift  = data.shift;
                this.checklistData  = data.checkListData;
                this.status = data.status;
                this.crew_signature = {
                    image   : data.crew_signature,
                    name    : 'signature.png',
                    type    : 'supervisor',
                    default : 'yes'
                };
                this.submitted_date = data.submitted_date;
                this.submitted_time = data.submitted_time;

                var role = this.$store.getters.currentUser.role;

                if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                    //this.disabledOptions = false;
                    this.ledit = true;
                } else if(((role == 'administrator') || (role == 'admin')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                    this.ledit = true;
                }

                this.getShiftData();
                this.getBuildings();
            }
        });

    },
    disabledDate(date){
      return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
    },

    updateRemarks(p_id, c_id){
        if(this.checklistData[p_id].data[c_id].condition == 'N/A'){
            this.checklistData[p_id].data[c_id].remarks = 'N/A';
        } else {
            if(this.checklistData[p_id].data[c_id].remarks == 'N/A'){
                this.checklistData[p_id].data[c_id].remarks = '';
            }
        }
    },

    checkdisabled(){
      if (this.ledit == true) {
        return false;
      }else if(this.disabledOptions == true){
        return true;
      }else{
        return false;
      }
    }

  },
  
  mounted(){
    this.useDefault();
    this.allSites();
    this.siteChange();

    if(this.$route.params.id){
      this.disabledOptions = true;
      this.getFormDetails();
    }
  },
}
</script>

<style scoped>
  .form-inline .custom-control-label {
      margin-bottom: 0;
      font-weight: normal;
      margin-top: 2px;
      text-transform: capitalize;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
